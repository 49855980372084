import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import Sidebar from 'bundles/App/pages/HelpCenter/Sidebar';
import { getFaqSections } from 'api/helpCenter';
import { Status } from 'libs/utils/api/types';
import Meta from 'styleguide/components/Meta/Meta';
import AppContext from 'contexts/AppContext/AppContext';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import FAQ from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/FAQ/FAQ';

const HelpCenterPage = () => {
  const appContext = React.useContext(AppContext);
  const [sections, setSections] = React.useState(appContext.options.sections);
  const [selectedSection, setSelectedSection] = React.useState(
    appContext.options.section || (sections && sections[0]) || null,
  );

  React.useEffect(() => {
    if (!sections) {
      getFaqSections().then(res => {
        if (res.status === Status.Ok) {
          setSections(res.payload.sections);
          setSelectedSection(res.payload.sections[0]);
        }
      });
    }
  }, []);

  return (
    <>
      <Meta
        title="Help Center | Printivity"
        description="Printivity is here to help and deliver our 5-star customer service to our customers. | Printivity"
        canonical="https://www.printivity.com/help-center"
      />
      <TitleContainer title="Help Center" />
      {sections && selectedSection && (
        <div className="mx-auto mb-10 max-w-base px-1">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:min-w-content mx-auto pt-4 sm:pt-6 lg:whitespace-nowrap lg:pt-20">
              <Sidebar selectedSection={selectedSection} sections={sections} />
            </div>
            <div className="flex flex-col">
              <h2 className="my-4 text-center font-hvMedium text-xl md:text-5xl">{selectedSection.name}</h2>
              <FAQ questions={selectedSection.faqs} expanded />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default HelpCenterPage;
